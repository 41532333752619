import { useReducer } from 'react';

import { successToast } from '../utils/toasts';

import {
  JOIN_ROOM,
  VOTED,
  CLEAR_POINTS,
  REVEAL_POINTS,
  LEAVE_ROOM,
} from '../sockets/SocketConnection';

const updatePlayersPoints = (playersState, payload) =>
  playersState.map(player => {
    if (player.id === payload.id) {
      player.point = payload.point;
    }
    return player;
  });

const clearPlayersPoints = playersState =>
  playersState.map(player => {
    return {
      ...player,
      point: '',
    };
  });

const initialState = {
  pointers: [],
  observers: [],
  scale: '',
  showPoints: false,
};

function reducer(state, action) {
  const { event, payload } = action;
  switch (event) {
    case JOIN_ROOM:
    case LEAVE_ROOM:
      const { players, observers, pointScale } = payload;
      return { 
        ...state,
        pointers: players,
        observers,
        scale: pointScale
      };
    case VOTED:
      return {
        ...state,
        pointers: updatePlayersPoints(state.pointers, payload),
      };
    case CLEAR_POINTS:
      successToast('Clearing Points!');
      return {
        ...state,
        pointers: clearPlayersPoints(state.pointers),
        showPoints: false,
      };
    case REVEAL_POINTS:
      successToast('Revealing Points!');
      return {
        ...state,
        showPoints: true,
      };
    default:
      throw new Error();
  }
}

export default function useSocketMessageReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { ...state, dispatch };
}
