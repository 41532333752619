import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Nav from './Nav';
import Ballot from './Ballot';
import Button from './Form/Button';
import RoleToggle from './RoleToggle';
import ParticipantRow from './ParticipantRow';
import useSocketMessageReducer from './useSocketMessageReducer';
import { POINTER } from '../constants/roles';
import { VOTED, CLEAR_POINTS, REVEAL_POINTS } from '../sockets/SocketConnection';
import { successToast } from '../utils/toasts';

const Room = ({ socketConnection, history, role }) => {
  const [view, setView] = useState(POINTER);
  const [sheetVisibility, setSheetVisibility] = useState(false);
  const myId = socketConnection.id;
  const { dispatch, pointers, observers, scale, showPoints } = useSocketMessageReducer();
  const pointSelection = pointers.length ? pointers.find(({ id }) => id === myId).point : "";
  const voted = pointSelection !== "";

  const changeViewAction = event => setView(event.target.value);
  const toggleSheetVisibility = () => setSheetVisibility(prevState => !prevState);
  const voteAction = value => socketConnection.send(VOTED, value);
  const revealPoints = () => socketConnection.send(REVEAL_POINTS);
  const clearPoints = () => socketConnection.send(CLEAR_POINTS);
  const copiedLink = () => successToast('Copied Link!');
  const leaveRoom = () => history.push('/');

  useEffect(() => {
    if (socketConnection.conn) {
      socketConnection.conn.addEventListener('message', e => {
        const data = JSON.parse(e.data);
        dispatch(data);
      });
    } else {
      history.push('/');
    }

    return () => {
      socketConnection.close();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pointersList = pointers.map(participant => (
    <ParticipantRow
      key={participant.id}
      view={view}
      participant={participant}
      isSelf={myId === participant.id}
      showPoints={showPoints}
    />
  ));
  const observersList = observers.map(participant => (
    <ParticipantRow
      key={participant.id}
      view={view}
      participant={participant}
      isSelf={myId === participant.id}
      showPoints={showPoints}
    />
  ));

  return (
    <>
      <div className="room-content">
        <RoleToggle
          onChangeAction={changeViewAction}
          role={view}
          pointers={pointers}
          observers={observers}
        />
        <div className="room-content__participant-list">
          {view === POINTER ? pointersList : observersList}
        </div>
        <Nav
          revealPointsAction={revealPoints}
          clearPointsAction={clearPoints}
          leaveRoomAction={leaveRoom}
          copiedLinkAction={copiedLink}
        />
      </div>
      <div className="home">
        {role === POINTER && (
          <Button id="change-vote-button" onClick={toggleSheetVisibility} className="btn--large">
            {voted ? 'Change Vote' : 'Vote'}
          </Button>
        )}
      </div>
      <Ballot
        visible={sheetVisibility}
        toggleSheetVisibility={toggleSheetVisibility}
        scale={scale}
        pointSelection={pointSelection}
        voteAction={voteAction}
      />
    </>
  );
};

export default connect(
  state => state,
  {}
)(memo(Room));
